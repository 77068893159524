module.exports = {
  // el = this defines the section of dom in view port
  // percentageOfView = this defines the percentage of section in view port
  isInViewport: (el, percentageOfView) => {
    const elemTop = el.offsetTop;
    const elemHeight = el.offsetHeight;
    const elemBottom = elemTop + elemHeight;
    const screenTop = document.documentElement.scrollTop;
    const screenBottom = screenTop + window.innerHeight;
    let result = 0;

    if (elemTop > screenTop) {
      if (elemBottom < screenBottom) {
        result = 100;
      } else if (elemTop < screenBottom) {
        result = ((screenBottom - elemTop) / elemHeight) * 100;
      }
    } else if (elemBottom > screenTop) {
      result = ((elemBottom - screenTop) / elemHeight) * 100;
    }

    if (+result.toFixed(2) >= percentageOfView) {
      return true;
    }

    return false;
  },
};
