import throttle from "lodash/throttle";
import { useEffect } from "react";

import { TIMINGS } from "@/constants";
import { isInViewport } from "@/utils/helpers/isInViewport";

const checkModule = (moduleList, moduleToView) => {
  let isModuleAvailable = false;

  moduleList &&
    moduleList.forEach((component) => {
      const {
        contentType: {
          sys: { id },
        },
      } = component.sys;

      if (id === moduleToView) {
        isModuleAvailable = true;
      }
    });

  return isModuleAvailable;
};

const useAcdlCustomEvent = (
  setNewDataLayer,
  moduleList,
  moduleToView,
  adobeData,
  selector,
  pageEvent = "scroll"
) => {
  useEffect(() => {
    if (!checkModule(moduleList, moduleToView)) {
      return;
    }

    const isSupported = window && window.addEventListener;

    if (!isSupported) {
      return;
    }

    let isAdobeDataAdded = false;

    const handleViewPortScroll = () => {
      const ele = document.querySelector(`[${selector}]`);

      if (ele && isInViewport(ele, 50) && !isAdobeDataAdded) {
        isAdobeDataAdded = true;
        setNewDataLayer(adobeData);
      }
    };

    const throttledHandleViewPortScroll = throttle(
      handleViewPortScroll,
      TIMINGS.THROTTLE_SCROLL_TIME_OUT
    );
    window.addEventListener(pageEvent, throttledHandleViewPortScroll);

    return () => {
      window.removeEventListener(pageEvent, throttledHandleViewPortScroll);
    };
  }, [moduleToView]); // eslint-disable-line react-hooks/exhaustive-deps -- WEB-2321

  return null;
};

export default useAcdlCustomEvent;
