import {
  PAGE_CATEGORY_ENUM,
  getClubStatus,
  processUrl,
} from "../acdl-data-layer";
import { PAGE_TYPE_ENUM, assemblePageObject } from "./global-tracking";
import { LEAD_FLOW_TYPES } from "./lead-flow";

const JOIN_FLOW_URL = "/join/step2/";

export const JOIN_FLOW_MEMBERSHIP_TYPES = {
  MULTIPLE: "multiple clubs",
  SINGLE: "single club",
};

const JOIN_FLOW_STEP_NAMES = {
  CLUB_SELECTED: "club selected",
  CONFIRMATION: "join confirmation",
  LEAD_ERROR: "join lead error",
  LEAD_SUBMIT: "join lead submit",
  LEAD_VIEW: "join lead view",
  MEMBERSHIP_SELECTED: "membership selected",
  MEMBERSHIP_VIEW: "membership view",
  SELECT_A_CLUB: "join select a club",
};

const JOIN_FLOW_CATEGORY_TYPES = {
  ENGAGE: "join flow | engage",
  NON_ENGAGE: "join flow | non-engage",
};

export const ACDL_JOIN_FLOW_TRACKING = {
  joinFlowSelectAClub: (url) => {
    const urlSections = processUrl(url);

    return {
      event: "page-load",
      joinFlow: {
        step: JOIN_FLOW_STEP_NAMES.SELECT_A_CLUB,
        type: LEAD_FLOW_TYPES.NON_EMBEDDED,
      },
      page: assemblePageObject(
        urlSections,
        undefined,
        PAGE_CATEGORY_ENUM.JOIN,
        PAGE_TYPE_ENUM.PROGRAMATIC
      ),
      user: {
        loginStatus: "not-authenticated",
      },
    };
  },

  joinFlowClubSelected: (
    type,
    facilityID,
    clubID,
    clubRegion,
    clubName,
    clubStatus,
    clubType
  ) => ({
    event: "join-flow",
    joinFlow: {
      clubID: clubID.toString(),
      clubName,
      clubRegion,
      clubStatus: getClubStatus(clubStatus),
      clubType: clubType && clubType.toLowerCase(),
      facilityID,
      step: JOIN_FLOW_STEP_NAMES.CLUB_SELECTED,
      type,
    },
  }),

  joinFlowMembershipSelected: (
    membership,
    potentialRevenue,
    currencyCode,
    facilityId = null
  ) => ({
    event: "join-flow",
    joinFlow: {
      currencyCode,
      membership,
      potentialRevenue,
      step: JOIN_FLOW_STEP_NAMES.MEMBERSHIP_SELECTED,
      ...(facilityId && { facilityID: facilityId }),
    },
  }),

  joinFlowLeadView: (facId) => {
    const url = JOIN_FLOW_URL + facId;
    const urlSections = processUrl(url);

    return {
      event: "page-load",
      joinFlow: {
        category: JOIN_FLOW_CATEGORY_TYPES.ENGAGE,
        step: JOIN_FLOW_STEP_NAMES.LEAD_VIEW,
      },
      page: assemblePageObject(
        urlSections,
        undefined,
        PAGE_CATEGORY_ENUM.JOIN,
        PAGE_TYPE_ENUM.PROGRAMATIC
      ),
    };
  },

  joinFlowMembershipView: (
    url,
    isEmbedded = false,
    isLocationAutoDetected = false
  ) => {
    const urlSections = processUrl(url);

    if (isEmbedded) {
      return {
        event: "join-flow",
        joinFlow: {
          autoDetection: isLocationAutoDetected ? "Y" : "N",
          step: JOIN_FLOW_STEP_NAMES.MEMBERSHIP_VIEW,
          type: LEAD_FLOW_TYPES.EMBEDDED,
        },
      };
    }

    return {
      event: "page-load",
      joinFlow: {
        autoDetection: isLocationAutoDetected ? "Y" : "N",
        step: JOIN_FLOW_STEP_NAMES.MEMBERSHIP_VIEW,
        type: LEAD_FLOW_TYPES.NON_EMBEDDED,
      },
      page: assemblePageObject(
        urlSections,
        undefined,
        PAGE_CATEGORY_ENUM.JOIN,
        PAGE_TYPE_ENUM.PROGRAMATIC
      ),
    };
  },

  joinFlowLeadSubmit: (
    hashedEmail,
    leadTransactionID,
    activationCode,
    formattedSource
  ) => ({
    event: "join-flow",
    joinFlow: {
      category: JOIN_FLOW_CATEGORY_TYPES.ENGAGE,
      hashedEmail,
      leadTransactionID,
      step: JOIN_FLOW_STEP_NAMES.LEAD_SUBMIT,
      type: LEAD_FLOW_TYPES.NON_EMBEDDED,
      ...(activationCode && { activationCode: activationCode.toLowerCase() }),
      ...(formattedSource && { source: formattedSource }),
    },
  }),

  joinFlowLeadError: (error) => ({
    event: "join-flow",
    joinFlow: {
      category: JOIN_FLOW_CATEGORY_TYPES.ENGAGE,
      error,
      step: JOIN_FLOW_STEP_NAMES.LEAD_ERROR,
    },
  }),

  joinScrollEvent: (isClubDetailPage = false) => ({
    event: "join-flow",
    joinFlow: {
      step: isClubDetailPage
        ? JOIN_FLOW_STEP_NAMES.MEMBERSHIP_VIEW
        : JOIN_FLOW_STEP_NAMES.SELECT_A_CLUB,
      type: LEAD_FLOW_TYPES.EMBEDDED,
    },
  }),

  joinFlowConfirmation: (url, barcode) => {
    const urlSections = processUrl(url);

    return {
      event: "page-load",
      joinFlow: {
        barcode,
        step: JOIN_FLOW_STEP_NAMES.CONFIRMATION,
      },
      page: assemblePageObject(
        urlSections,
        undefined,
        PAGE_CATEGORY_ENUM.JOIN,
        PAGE_TYPE_ENUM.PROGRAMATIC
      ),
    };
  },

  joinCtaEvent: (ctaName, sourceName) => ({
    event: "join-flow",
    joinFlow: {
      source: `${ctaName.toLowerCase()} | ${sourceName}`,
      step: "join cta",
    },
  }),

  joinFlowMembershipPageView: (
    url,
    facilityID,
    clubID,
    clubRegion,
    clubName,
    clubStatus,
    clubType
  ) => {
    const urlSections = processUrl(url);

    return {
      event: "page-load",
      joinFlow: {
        clubID: clubID.toString(),
        clubName,
        clubRegion,
        clubStatus: getClubStatus(clubStatus),
        clubType: clubType && clubType.toLowerCase(),
        facilityID,
        step: JOIN_FLOW_STEP_NAMES.MEMBERSHIP_VIEW,
        type: LEAD_FLOW_TYPES.NON_EMBEDDED,
      },
      page: assemblePageObject(
        urlSections,
        undefined,
        PAGE_CATEGORY_ENUM.JOIN,
        PAGE_TYPE_ENUM.PROGRAMATIC
      ),
      user: {
        loginStatus: "not-authenticated",
      },
    };
  },
};
