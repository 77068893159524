import { useRouter } from "next/router";

import {
  MOBILE_APP_WEB_VIEW_KEY,
  MOBILE_APP_WEB_VIEW_VALUE,
} from "@/constants";

const useWebView = () => {
  const router = useRouter();
  const isWebView =
    router?.query?.[MOBILE_APP_WEB_VIEW_KEY] === MOBILE_APP_WEB_VIEW_VALUE;

  return isWebView;
};

export default useWebView;
